module.exports = {
  email: 'ijazrehman.dev@gmail.com',

  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/ijazulrehman',
    },
    {
      name: 'Linkedin',
      url: 'https://linkedin.com/in/ijazulrehman',
    },
    {
      name: 'Facebook',
      url: 'https://facebook.com/ijazulreh',
    },
    {
      name: 'Behance',
      url: 'https://behance.net/ijazulrehman',
    },
    // {
    //   name: 'Twitter',
    //   url: 'https://twitter.com/ijazulreh',
    // },
    // {
    //   name: 'Instagram',
    //   url: 'https://instagram.com/ijazulreh',
    // }
  ],

  navLinks: [
    {
      name: '.about()',
      url: '/#about',
    },
    {
      name: '.experience()',
      url: '/#jobs',
    },
    {
      name: '.work()',
      url: '/#projects',
    },
    {
      name: '.contact()',
      url: '/#contact',
    },
  ],

  colors: {
    green: '#64ffda',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },

  srConfig: (delay = 200, viewFactor = 0.25) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};
